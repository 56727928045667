// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../src/stylesheets/application.scss";

// import all image files in a folder:
require.context('../audio', true)
require.context('../images', true)

import 'bootstrap';
import 'bootstrap-select';
import Chart from 'chart.js/auto';
import autocolors from 'chartjs-plugin-autocolors';
import SignaturePad from 'signature_pad/dist/signature_pad'

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap-datepicker");
require("devbridge-autocomplete");
require("jquery-lazy")
require("select2")

import modernizr from 'modernizr';
import './order_form';
import './events';
import './admin/student_form';
import './admin/administrator_form';
import {autoIncrementEndTime} from "./auto-increment-end-time";
import './send_pass_via_sms'
import './qr_scanner';
import './admin/dashboard';
import './admin/instructor_payment';
import './admin/quick_summary';
import './admin/student_registration_form';
import './admin/student';
import './admin/contract';

global.Chart = Chart;
global.Chart.register(autocolors);

document.addEventListener("turbolinks:load", () => {

  console.log('turbo loaded.')
  $(function () {
    'use strict'
    /**
     * Datepicker progressive enhancement for devices without 'date' support
     */
    if (!modernizr.inputtypes['date']) {
      // Auto show only on devices that don't support 'date'
      $('*[data-js-datepicker]').datepicker({
        format: 'yyyy-mm-dd',
        weekStart: 1,
        todayBtn: 'linked',
        daysOfWeekHighlighted: '0,6',
        todayHighlight: true
      });
    }

    /**
     * Auto refocuser - for focusing the input on another object when clicked
     */
    $('[data-js-refocus-target]').on('click', function () {
      $($(this).attr('data-js-refocus-target')).focus();
    });
  });

  $(function () {
    'use strict'
    /**
     * Bootstrap compliant off canvas drawer menu
     * Modified by Nimrod Evans
     * Updated: 2020-02-17
    */
    $('[data-js-toggle="offcanvas"]').on('click', function () {
      $($(this).attr('data-target')).toggleClass('open');
      if($(this).attr('aria-expanded') === 'true') {
        $(this).attr('aria-expanded', 'false');
      } else {
        $(this).attr('aria-expanded', 'true');
      }
    });

    // remove field_with_errors surrounding custom radio
    // so the css can reach them
    $('.custom-radio .field_with_errors').replaceWith(function() {
      return $(this).children();
    });
  });

  // Set up auto increment on inputs with autotime set to start
  $('input[data-js-autotime=start]').autoIncrementEndTime();

  if ($('#account-signature-wrapper').length) {
    const canvas = document.querySelector('canvas')
    const signaturePad = new SignaturePad(canvas)

    signaturePad.toSVG()

    if ($('#student_account_holder_signature').val() != '') {
      signaturePad.fromDataURL($('#student_account_holder_signature').val())
    }

    signaturePad.addEventListener("endStroke", () => {
      var data = signaturePad.toDataURL('image/svg+xml')

      $('#student_account_holder_signature').val(data)
      $('#next-option').prop('disabled', false)
    })

    document.getElementById('clear-sig').addEventListener('click', function () {
      signaturePad.clear()
      $('#student_account_holder_signature').val('')
      $('#next-option').prop('disabled', true)
    })
  }

  // Activate bootstrap tab deep linking
  // Detect if tabs are on the page
  if ($('[data-js-deeplink]').length) {
    const anchor = window.location.hash;
    $(`[data-js-deeplink][href="${anchor}"]`).tab('show');
  }
  // For tabs, add '#' anchor link to URL when link is clicked
  $('a[data-js-deeplink]').on("click", function() {
    const url = window.location.href;
    const hash = $(this).attr("href");
    let newUrl;
    newUrl = url.split("#")[0] + hash;
    history.replaceState(null, null, newUrl);
  });

  if($('a[data-js-href').length) {
    $('a[data-js-href').each(function(){
      const url = $(this).data('js-href')
      $.get(url, function(data) {}, 'script');
    })
  }

  if ($('#admin-student-detail').length) {
    var anchor = $(location).attr('hash')
    if (window.location.hash.length) {
      if (anchor == '#student-classes') {
        $('#admin-student-session-types').removeClass('hidden')
      }
    } else {
      $('#admin-student-session-types').removeClass('hidden')
    }
  }

  $('.admin-student-navs').on('click', function(e) {
    var anchor = $(this).attr('href')
    if (anchor == '#student-classes') {
      $('#admin-student-session-types').removeClass('hidden')
    } else {
      $('#admin-student-session-types').addClass('hidden')
    }
  })

  $('.session-type-filter').on('click', function(e) {
    var member_id = $(this).data('member-id')
    var session_type = $(this).data('session-type')
    var url = '/admin/students/' + member_id + '?session_type=' + session_type

    window.location.href = url
  })

  $('input[data-confirm-before-switching-tab][data-switch-to-tab]').on('click', function(event) {
    var $form = $(this).parent('form');

    var tab = $(this).data('switch-to-tab');
    var message = $(this).data('confirm-before-switching-tab');

    // If the tab is already visible, show the confirmation message and prevent form submission if the user cancels.
    if ($('.tab-pane#' + tab).is(":visible")) {
      if (!window.confirm(message)) {
        event.preventDefault();
      }
    }
    // If we're on a different tab:
    // * Prevent automatic submission of the form.
    // * Show the tab.
    // * After the tab is shown, show the confirmation message (in a timeout to allow the tab contents to render first).
    // * Manually submit the form if the user confirms.
    else {
      event.preventDefault();

      $('a[data-toggle="tab"][href="#' + tab + '"]')
        .tab('show')
        .one('shown.bs.tab', function (e) {
          setTimeout(function() {
            if (window.confirm(message)) {
              $form.submit();
            }
          }, 500);
        });
    }
  });

  // On the /events/:event_id page there is a Bootstrap btn-card div that is very complex in that it has a checkbox,
  // label, image, spans and links in it. We want the entire thing (except for the checkbox and its label) to be a
  // link, but that is breaking the layout no matter what I do (e.g. wrapping the entire thing in an a element, etc).
  // Resorted to JavaScript below. Any click on the card except on the label, input or a elements within, will trigger
  // navigation to the link URL.
  $('.btn-card-clickable').on('click', function(event) {
    if ($(event.target).is('label, input, a')) {
      return;
    }

    var url = $(this).find('a').prop('href');
    window.location.href = url;
  });

  if ($('.notif-item-live').length > 0) {
    var notifItem = $('.notif-item-live')

    if (notifItem.hasClass('alert-dismissible') == false) {
      markNotificationAsRead(notifItem.data('reference'), false)
    }
  }

  $(document).on('close.bs.alert', '.notif-item-live', function(event) {
    if ($(this).hasClass('alert-dismissible')) {
      markNotificationAsRead($(this).data('reference'), true)
    }
  })

  // default select2 setup
  $('.select2').select2({
    width: '100%',
    tags: true,
    tokenSeparators: [','],
    allowClear: true
  })

  function markNotificationAsRead(reference, pick_next) {
    $.ajax({
      url: '/profile/mark_read?reference=' + reference + '&pick_next=' + pick_next,
      type: 'GET',
      dataType: 'script'
    })
  }

  $(document).on('click', '.change-region-link', function(event) {
    event.preventDefault()
    $('#region-options').modal('show')
  })

  $(document).on('click', '.create-new-note', function(event) {
    event.preventDefault()
    $('#note-form').modal('show')
  })

  $(document).on('click', '.create-new-event-note', function(event) {
    event.preventDefault()
    $('#event-note-form').modal('show')
  })

  $(document).on('click', '.change-region-item', function(event) {
    $('#region-options').modal('hide')
  })

  $('.selectpicker').selectpicker();

  if ($('.open-region-option').length > 0) {
    $('#region-options').modal('hide')
    setTimeout(function() {
      $('#region-options').modal('show')
    }, 500)
  }

  if ($('#add-student-page').length > 0) {
    setActiveSessionOption()
  }

  function setActiveSessionOption() {
    var updatedIds = []
    var eventIds = window.localStorage.getItem('event_ids')
    const eventOptions = document.querySelectorAll(".other-session")

    if (eventIds) {
      eventOptions.forEach( function(item) {
        eventIds.split(',').forEach( function(event_id) {
          if (event_id == $(item).attr('data-id')) {
            updatedIds.push(event_id)
            item.classList.remove('btn-light')
            item.classList.add('btn-danger')
          }
        })
      })
      $('.student-lookup-frm input[name="event_ids"]').val(updatedIds)
      $('.new-student-frm input[name="event_ids"]').val(updatedIds)
      $('#add-by-membership-number input[name="event_ids"]').val(updatedIds)
      window.localStorage.removeItem('event_ids')
    }
  }

});

window.SelectPicker = {
  init: function(element) {
    element.selectpicker();
  }
};

$(document).on('click', '.student-tab-lnk', function(e) {
  var currentTab = $(this).data('tab')

  $('.prev-next-lnk').attr('data-tab', currentTab)
})

$(document).on('click', '.prev-next-lnk', function(e) {
  var href = $(this).attr('href')
  var currentTab = $(this).attr('data-tab')

  if (currentTab != '') {
    e.preventDefault()
    window.location.href = href + '&student_tab=' + currentTab
  }
})

$(document).on('change', '.notif-country', function(event) {
  $.ajax({
    url: '/admin/banner_messages/country_regions?countries=' + $(this).val(),
    type: 'GET',
    dataType: 'script'
  })
})

$(document).on('click', '.to-student-info', function(event) {
  let redir = $(this).data('info')

  window.localStorage.setItem('event_dir', window.location.href)
  window.location.href = redir
})

$(document).on('click', '.back-option', function(event) {
  let eventDir = window.localStorage.getItem('event_dir')

  event.preventDefault()
  if (eventDir != null) {
    localStorage.removeItem("event_dir");
    window.location.href = eventDir
  } else {
    window.location.href = '/events'
  }
})

$(document).on('click', '.other-session', function(event) {
  let event_ids = []

  if ($(this).hasClass('btn-light')) {
    $(this).removeClass('btn-light')
    $(this).addClass('btn-danger')
  } else {
    $(this).removeClass('btn-danger')
    $(this).addClass('btn-light')
  }

  $('.event-options').each( function(elm) {
    if ($(this).hasClass('btn-danger')) {
      event_ids.push($(this).attr('data-id'))
    }
  })

  if (event_ids.length > 0) {
    $('input[name="event_ids"]').val(event_ids)
  }
})

$(document).on('keydown', '.multiline-note', function(e) {
  const keyCode = e.which || e.keyCode
  var note_content = $(this).val()

  if (keyCode === 13) {
    $(this).val(note_content + '\r\n')
    e.preventDefault()
  }
})

$(document).on('change', '#event_location_id', function(event) {
  var mode = $(this).find(':selected').data('mode')
  var superCentre = $(this).find(':selected').data('super-centre')

  var location_id = $(this).val()

  if (mode == 'new') {
    $.ajax({
      method: 'GET',
      url: '/events/start_time.json',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: {
        location_id: location_id
      },
      success: function(data) {
        $('#event_event_date').val(data['date'])
        $('#event_start_at').val(data['start_time'])
        $('#end-at').val(data['end_time'])
      }
    })
  }

  if (superCentre) {
    $('#event_type_fitness').show();
  } else {
    $('#event_type_fitness').hide();
  }
});

$(document).on('click', '#reveal-phone', function(event) {
  var event = ''
  var student = $(this).data('student')

  if ($(this).data('event') != undefined) {
    event = $(this).data('event')
  }

  $.get({
    url: '/students/' + student + '/log_access?event_id=' + event
  }).done( function(data) {
    $('#student-phone').html(data.phone)
  }).fail( function() {
    $('#student-phone').html('There was an error processing your request.')
  })
})

$(document).on('changed.bs.select', '#event_sensei_id', function(e, clickedIndex, isSelected, previousValue) {
  var selected_sensei = $(this).val()
  var other_sempai = $('#event_other_sempais_').val()
  var valid_other_sempai = []

  if (other_sempai.length > 0) {
    if (other_sempai.indexOf(selected_sensei) > -1) {
      valid_other_sempai = other_sempai.filter(item => item !== selected_sensei)
      $('#event_other_sempais_').selectpicker('val', valid_other_sempai)
    }
  }

  $("#event_other_sempais_ option[value='" + previousValue + "']").prop('disabled', false)
  $("#event_other_sempais_ option[value='" + selected_sensei + "']").prop('disabled', true)
  $('#event_other_sempais_').selectpicker('refresh')
})

$(document).on('click', '#submit-event', function(event) {
  var eventForm = $('#event-form')
  var eventData = $(eventForm).serializeArray()
  var skip_id = $(this).data('skip')

  if (skip_id != '') {
    eventData.push({name: "skip_id", value: skip_id});
  }

  $.get('/events/check_duplicate', eventData)
    .done( function(data) {
      if (data.duplicate_id == '') {
        $(eventForm).trigger('submit')
      } else {
        $('#duplicate-session-confirm').data('ref', data.duplicate_id)
        $('#duplicate-session-confirm').modal('show')
      }
    })
})

$(document).on('click', '#create-new-session', function(event) {
  var eventForm = $('#event-form')

  $(eventForm).trigger('submit')
})

$(document).on('click', '#join-exiting-session', function(event) {
  var url = document.location.href
  var dup_id = $('#duplicate-session-confirm').data('ref')

  window.location.href = '/events/' + dup_id + '?' + url.substring(url.indexOf('?') + 1)
})

$(document).on('click', '.student-status', function(event) {
  var status_value = $(this).data('value')

  $('#q_active_eq').val(status_value)
  $('#admin-student-search').submit()
})

$(document).on('keyup', '.to-title-case', function() {
  var str = $(this).val()

  $(this).val(str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()))
})

// store the event_ids before leaving the add student page
$(document).on('click', '.student-lookup-frm', function(event) {
  var event_ids = $('.student-lookup-frm input[name="event_ids"]').val()
  window.localStorage.setItem('event_ids', event_ids)
})

$(document).on('click', '.new-student-frm', function(event) {
  var event_ids = $('.new-student-frm input[name="event_ids"]').val()
  window.localStorage.setItem('event_ids', event_ids)
})

$(document).on('click', '.admin-student-region-item', function(event) {
  var location_info = $(this).data('location')
  $('#q_location_region_eq').val(location_info.replace(/-/g, '>'))
  $('#admin-student-search').submit()
})

$(document).on('click', '.instructor-search-item', function(event) {
  var selected_item = $(this)
  var frm_acc = $('[name="to_account"]')

  $('.instructor-search-item').removeClass('active')
  $(frm_acc).val($(selected_item).data('ref'))
  $(selected_item).addClass('active')
})

$(document).on('click', '.admin-region-stat-item', function() {
  var selected_item = $(this)

  $('.admin-region-stat-item').removeClass('active')
  $(selected_item).addClass('active')
  $('.region-stat-label').html($(selected_item).text())
})

$(document).on('click', '.student-search', function(event) {
  var with_option = false
  $('.required-option').each( function(indx, val) {
    if (this.value != '') {
      with_option = true
      return false
    }
  })

  if (with_option) {
    $('form.student-filter').submit()
  } else {
    alert('Please enter at least one field to search.')
  }
})

$(document).on('submit', '#search-student-for-event, #admin-student-search', function(event) {
  var with_active_filter = false
  var with_option = false

  if ($('#q_active_eq').val() != '') {
    with_active_filter = true
  }

  $('.required-option').each( function(indx, val) {
    if (this.value != '') {
      with_option = true
      return false
    }
  })

  if ((with_option == false) && (with_active_filter == false)) {
    event.preventDefault()
    alert('Please enter at least one field to search.')
  }
})

$(document).on('click', '.attendance-item', function(e) {
  var event_id = $(this).data('event-id')

  window.location.href = '/events/' + event_id
})

$(document).on('click', '.family-member', function(e) {
  var member_id = $(this).data('member-id')
  window.location.href = '/admin/students/' + member_id
})

$(document).on('click', '.report-wrapper-item', function(e) {
  let item = $(this).find('.report-item')
  $(item).attr('checked', !$(item).prop('checked'))
})

$(document).on('click', '#clear-report-selecdtion', function(e) {
  $('.report-item').attr('checked', false)
})

$(document).on('click', '#all-weekly-reports', function(e) {
  // check if a region is selected
  let region = $('#report_region').val()
  if (region != '') {
    $('.weekly-report-item').attr('checked', true)
    $('#admin-reports').submit()
  } else {
    alert('Please select the region for these reports.')
  }
})

$(document).on('change', '#student_introduced_by', function(event) {
  $.ajax({
    url: '/admin/students/regions?user_id=' + $(this).val(),
    type: 'GET',
    dataType: 'script'
  })
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.initSelectPicker = function() {
  $('.selectpicker').selectpicker();
}
